import jwt_decode                     from "jwt-decode";
import dynamic                        from "next/dynamic";
import { useRouter }                  from "next/router";
import React, { useEffect, useState } from "react";
import { useTranslation }             from "react-i18next";

import { useSetLoggedIn, useSetProfileData }  from "_common/hooks/auth";
import { useGetAppLocale, useSetLoginPopup }  from "_common/hooks/global";
import { useGetDeviceType }                   from "_common/hooks/global";
import Toast                                  from "_common/toast";
import { isCreatorDashboardPage, reloadPage } from "_common/utils";
import { storeCacheId }                       from "_common/utils/user";
import { enableOtpInput }                     from "_services/api/auth";

import { loginUser, loginUserWithGoogle } from "./apis";

const Presentation       = dynamic ( () => import ( "./presentation" ) );
const PresentationMobile = dynamic ( () => import ( "./presentationMobile" ) );

const component = props => {
	const { t } = useTranslation ();

	const otpLength    = 6;
	const mobileLength = 10;

	const router = useRouter ();

	const [ state, _setState ] = useState ( {
		step         : 1,
		isSendingOtp : false,
		count        : 59,
		otp          : "",
		mobile       : "",
		otpError     : false
	} );

	const isActivatePage = props?.page === "activate";

	const stateRef = React.useRef ();

	stateRef.current = state;

	const setState = newStates => {
		_setState ( { ...stateRef.current, ...newStates } );
	};

	const interval       = React.useRef ( null );
	const setUserProfile = useSetProfileData ();
	const setIsLoggedIn  = useSetLoggedIn ();
	const setLoginPopup  = useSetLoginPopup ();
	const appLocale      = useGetAppLocale ();
	const deviceType     = useGetDeviceType ();
	const isMobile       = deviceType === "mobile";

	useEffect ( () => {
		document.addEventListener ( "keydown", handleKeyDown );

		return () => {
			document.removeEventListener ( "keydown", handleKeyDown );
			stopCountDown ();
		};
	}, [] );

	const goBack = step => {
		stopCountDown ();

		setState ( {
			step     : step,
			otpError : false
		} );
	};

	const handleNumberChange = e => {
		setState ( {
			mobile            : e,
			mobileNumberError : ""
		} );
	};

	const handleOtpChange = otpValue => {
		if ( otpValue.toString ().length <= 6 ) {
			setState ( {
				otp               : otpValue,
				otpError          : false,
				mobileNumberError : ""
			} );
		}
	};

	/* Handler function for submitting mobile number
  and OTP through Enter key of keyboard */
	const handleKeyDown = ( e, step ) => {
		if ( e.key === "Enter" || e.key === "NumpadEnter" ) {
			if ( step === 1 ) {
				e.preventDefault ();
				submitForOtp ( e );
			} else {
				e.preventDefault ();
				submitOtpLogin ( e );
			}
		}
	};

	const submitForOtp = async e => {
		if ( state.mobile.toString ().length !== mobileLength ) {
			Toast.error ( `${ t ( "valid_mobile" ) }` );

			return;
		}

		setState ( { isSendingOtp: true } );
		e.preventDefault ();

		const countryCode = "IN";

		try {
			const result = await enableOtpInput ( `+91${ state.mobile }`, countryCode );

			if ( !result )
				return;

			const { otpTxnId } = result;

			setState ( {
				step              : 2,
				otpTxnId          : otpTxnId,
				isSendingOtp      : false,
				isResendOtp       : false,
				mobileNumberError : "",
				count             : 59
			} );

			startCountDown ();
		} catch ( e ) {
			setState ( {
				isSendingOtp      : false,
				mobileNumberError : e.displayMsg
			} );
			Toast.error ( e.displayMsg );
		}
	};

	const submitOtpLogin = async e => {
		if ( state.otp.toString ().length !== otpLength )
			return;

		e.preventDefault ();

		const countryCode = "IN";

		const { otpTxnId, otp, mobile } = state;

		if ( !otp )
			return setState ( { otpError: true, otp: "" } );

		const data = {
			locale : appLocale,
			mobile : `+91${ mobile }`,
			pushId : "webPushId",
			countryCode,
			otp    : otp.toString (),
			otpTxnId
		};

		try {
			const { userProfileData } = await loginUser ( data );

			storeCacheId ( userProfileData?.id, isMobile );
			setUserProfile ( userProfileData );
			setLoginPopup ( false );
			setIsLoggedIn ( true );
			onSuccessLogin ();

			if ( !isActivatePage ) {
				Toast.info ( "Successfully logged in." );
				setTimeout ( reloadPage, 200 );
			}
		} catch ( err ) {
			Toast.error ( err.displayMsg );
			setState ( { otpError: true, otpErrorMsg: err?.displayMsg } );
		}
	};

	const handleGoogleLogin = async credentialResponse => {
		var decoded = jwt_decode ( credentialResponse.credential );

		let loginData = {
			externalId         : decoded.sub,
			name               : decoded.name,
			photo              : decoded.picture,
			externalIdToken    : credentialResponse.credential,
			locale             : "en",
			provider           : "google",
			communicationEmail : decoded.email
		};

		const { userProfileData } = await loginUserWithGoogle ( loginData );

		storeCacheId ( userProfileData?.id, isMobile );
		setLoginPopup ( false );
		setUserProfile ( userProfileData );
		setIsLoggedIn ( true );
		onSuccessLogin ();

		if ( !isActivatePage ) {
			Toast.info ( "Successfully logged in." );

			setTimeout ( reloadPage, 200 );
		}

	};

	const handleCloseLogin = () => {
		setState ( {
			step              : 1,
			isSendingOtp      : false,
			mobile            : "",
			otp               : "",
			otpTxnId          : "",
			mobileNumberError : ""
		} );

		stopCountDown ();
		setLoginPopup ( false );
	};

	const startCountDown = () => {
		interval.current = setInterval ( () => {
			const __count = stateRef.current.count;

			if ( __count <= 0 ) {
				stopCountDown ();
				setState ( { isResendOtp: true } );
			} else {
				setState ( {
					count: __count <= 10 ? `0${ __count - 1 }` : __count - 1
				} );
			}
		}, 1000 );
	};

	const stopCountDown = () => {
		clearInterval ( interval.current );
	};

	const handleBackButton = () => {
		stopCountDown ();
		setState ( {
			step         : 1,
			isSendingOtp : false,
			count        : 59,
			otp          : "",
			mobile       : "",
			otpError     : false
		} );
	};

	const toggleModalDisplay = () => {
		setLoginPopup ( false );
	};

	const onSuccessLogin = () => {
		const isCreatorDashboard = isCreatorDashboardPage ( { router } );

		if ( isCreatorDashboard )
			router.push ( "/creator-dashboard" );
	};

	if ( isMobile )
		return (
			<PresentationMobile
				appLocale          = { appLocale }
				goBack             = { goBack }
				handleBackButton   = { handleBackButton }
				handleCloseLogin   = { handleCloseLogin }
				handleGoogleLogin  = { handleGoogleLogin }
				handleKeyDown      = { handleKeyDown }
				handleNumberChange = { handleNumberChange }
				handleOtpChange    = { handleOtpChange }
				hideHeader         = { props?.hideHeader }
				isActivatePage     = { isActivatePage }
				loginPopupStatus   = { props?.showLoginDialog }
				mobileLength       = { mobileLength }
				otpLength          = { otpLength }
				state              = { state }
				submitForOtp       = { submitForOtp }
				submitOtpLogin     = { submitOtpLogin }
				toggleModalDisplay = { toggleModalDisplay }
			/>
		);

	return (
		<Presentation
			goBack             = { goBack }
			handleCloseLogin   = { handleCloseLogin }
			handleGoogleLogin  = { handleGoogleLogin }
			handleKeyDown      = { handleKeyDown }
			handleNumberChange = { handleNumberChange }
			handleOtpChange    = { handleOtpChange }
			isActivatePage     = { isActivatePage }
			loginPopupStatus   = { props?.showLoginDialog }
			mobileLength       = { mobileLength }
			otpLength          = { otpLength }
			state              = { state }
			submitForOtp       = { submitForOtp }
			submitOtpLogin     = { submitOtpLogin }
		/>
	);
};

export default component;
